.about {
  text-align: center;
  padding-bottom: 2rem;
}

.about__welcome {
  margin-top: 4rem;
}

.about__title {
  margin-bottom: 3rem;
}

.about__image {
  width: 50%;
  margin-bottom: 3rem;
}

.about__paragraph {
  text-align: justify;
  width: 80%;
  margin-left: 10%;
}

@media screen and (min-width: 480px) {
  .about__image {
    width: 30%;
    margin-bottom: 6rem;
  }

  .about__paragraph {
    width: 60%;
    margin-left: 20%;
  }
}

@media screen and (min-width: 1025px) {
  .about {
    display: flex;
    flex-direction: column;
  }

  .about__title {
    margin-bottom: 6rem;
  }

  .about__bottom {
    width: 70%;
    margin-left: 15%;
    display: flex;
  }

  .about__left {
    order: 0;
  }

  .about__paragraph {
    margin-left: 0;
    width: 90%;
  }

  .about__image {
    order: 1;
    width: 50%;
  }
}
