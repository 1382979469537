/*landing page */

.home__landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.home__backgroundImage {
  position: absolute;
  bottom: 0;
  height: 90%;
  z-index: -1;
  max-width: 100%;
  object-fit: cover;
}

.home__landingTitle {
  width: fit-content;
  text-align: center;
  font-weight: 700;
}

.home__landingPrice {
  margin-top: 1rem;
  width: fit-content;
  text-align: center;
}

.home__landingBigP {
  font-size: 1.3rem;
  font-weight: 700;
}

.home__signUp {
  margin-top: 2rem;
}

.home__signUp > form > input {
  outline: none !important;
  -webkit-box-shadow: -1px 5px 11px -2px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 11px -2px rgba(0, 0, 0, 0.25);
  padding-left: 1rem;
  border: none;
  border: 1px solid lightgray;
}

.home__signUp > form > button {
  display: none !important;
}

.home__preLaunch {
  margin-top: 20vh;
  width: 80vw;
  padding: 2rem 1rem;
  text-align: center;
  -webkit-box-shadow: -1px 5px 11px -2px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 11px -2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: white;
}

/* Home about */
.home__about {
  padding: 1rem;
}

.home__aboutBigTitle {
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.home__aboutQuote {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
}

.home__aboutText {
  text-align: justify;
}

.home__aboutImage {
  margin-top: 2rem;
  width: 80%;
  margin-left: 10%;
}

.home__aboutBigPrice {
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
}

@media screen and (min-width: 480px) {
  .home__preLaunch {
    width: 50%;
  }

  .home__about,
  .home__section {
    width: 80%;
    margin-left: 10%;
  }

  .home__aboutBigTitle {
    margin-bottom: 4rem;
  }

  .home__aboutQuote ~ .home__aboutQuote {
    margin-bottom: 3rem;
  }

  .home__sectionImage {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1025px) {
  .home__backgroundImage {
    object-position: top;
    width: 100%;
    height: 95vh;
  }

  .home__preLaunch {
    width: 30%;
  }

  .home__about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home__aboutBigTitle {
    width: 50%;
    margin-bottom: 6rem;
  }

  .home__aboutTop {
    display: flex;
  }
  .home__aboutImage {
    width: 45%;
  }
}
