.faq {
  text-align: center;
}

.faq__title {
  margin: 4rem 0 3rem;
}

.faq__searchIntro,
.faq__title {
  width: 80%;
  margin-left: 10%;
}

.faq__searchIntro {
  margin-bottom: 2rem;
}

.faq__answer {
  text-align: justify;
}

@media screen and (min-width: 480px) {
  .faq__searchIntro {
    margin-bottom: 5rem;
  }

  .faq__list {
    width: 60%;
    margin-left: 20%;
  }
}

@media screen and (min-width: 1025px) {
  .faq__question {
    text-align: left;
  }
}
