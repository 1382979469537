.homeSection {
  margin-top: 5rem;
  padding: 1rem;
}

.homeSection > h1 {
  border-left: 4px solid #fce80a;
  padding-left: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.homeSection__text {
  text-align: justify;
}

.homeSection__image {
  margin-top: 2rem;
  width: 100%;
}

@media screen and (min-width: 480px) {
  .homeSection {
    width: 80%;
    margin-left: 10%;
  }

  .homeSection__image {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1025px) {
  .homeSection__bottom {
    display: flex;
  }

  .homeSection__text {
    flex: 0.4;
    margin-top: 5rem;
  }

  .homeSection__image {
    width: 100%;
    margin-left: 10%;
    flex: 0.6;
  }
}
