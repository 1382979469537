.navbar {
  padding-top: 0;
  padding-bottom: 0;
  background-color: white !important;
  height: 10vh;
}

.navbar > button {
  outline: none;
}

.header__logo {
  height: 3rem;
}

.header__right {
  flex: 0.3;
  display: flex;
  justify-content: space-evenly;
}

.header__link {
  font-weight: 500;
  color: black;
  margin-right: 1rem;
}

.header__link:hover {
  text-decoration: none;
  color: black;
}

.navbar-collapse {
  background: white;
}
